$(document).ready(() => {
  $(
    [
      '#congresses_users_register_professional_group',
      '#congresses_users_insert_professional_group',
      '#user_professional_group_id',
      '#user_professional_group'
    ].join(', ')
  ).on(
    'change',
    change => {
      const groups = $(change.target)
        .find('option')
        .toArray()
        .reduce((acc, el) => ({ ...acc, [$(el).html()]: $(el).val() }), {})

      const doctor = groups['Lekarz'],
            pharmacist = groups['Farmaceuta'],
            nurse = groups['Pielęgniarka / Położna'],
            student = groups['Student'],
            representant = groups['Reprezentant'],
            veterinary = groups['Lekarz weterynarii']
  
      const professional_group = $(change.target).children('option:selected').val()
  
      if (professional_group == doctor) {
        $('.block_specializations').show()
      } else {
        $('.block_specializations').hide()
      }
  
      if ( [doctor, nurse, pharmacist, veterinary].includes(professional_group) ){
        $('.block_npwz').show()
      } else {
        $('.block_npwz').hide()
      }
  
      if (professional_group == student) {
        $('.block_student').show()
      } else {
        $('.block_student').hide()
      }
  
      if ([representant].includes(professional_group)) {
        $('.block_company, .block_representant').show()
      } else {
        $('.block_company, .block_representant').hide()
      }
    }
  );
});
